<template>
  <div class="orderinfo">
     <div class="title">
        <img src="../../../../src/assets/image/black.png" alt="" @click="goblack">
        <span>订单详情</span>
     </div>
     <div class="status">
        <div class="name">
          {{orderInfo.stateText}}
        </div>
        <div class="tig" v-if="orderInfo.state == 3">
          商品已发货，请您耐心等待
        </div>
     </div>
      <div class="site" v-if="orderInfo.state == 2">
          <div class="site_text">
              <div class="left">
                <img class="imgdinwei" src="../../../../src/assets/image/outku.png" alt="">
                <div class="text1">出库中</div>
              </div>
              <img class="imgright" src="../../../../src/assets/image/right.png" alt="">
          </div>
          <div class="time">{{NowTime}}</div>
      </div>
      <div class="site site2" @click="gologistics(1)">
          <div class="site_text">
              <div class="left">
                <img class="imgdinwei" src="../../../../src/assets/image/dinwei.png" alt="">
                <div class="text1">{{orderInfo.contact}}</div>
              </div>
          </div>
          <div class="time">{{orderInfo.address}}</div>
      </div>
      <!-- 商品 -->
      <div class="goods" v-for="(item, index) in orderInfo.sub" :key="index">
         <img :src="item.topPic" alt="">
         <div class="goodinfo">
            <div class="name">{{item.name}}</div>
            <div class="tags"><span>{{item.skuAttr}}</span><span>×{{item.number}}</span></div>
            <div class="price">
              ￥{{item.skuPrice}}
            </div>
         </div>
      </div>
      <!-- 订单 -->
      <div class="order_nav">
         <div class="order_flex">
            <div class="left">订单编号</div>
            <div class="right">{{orderInfo.orderId}}</div>
         </div>
         <div class="order_flex">
            <div class="left">下单时间</div>
            <div class="right">{{orderInfo.createTime}}</div>
         </div>
         <div class="order_flex">
            <div class="left">商品金额</div>
            <div class="right">{{orderInfo.amount}}</div>
         </div>
         <div class="order_flex" v-if="orderInfo.state > 1">
            <div class="left">支付方式</div>
            <div class="right">{{orderInfo.payWayText}}</div>
         </div>
         <div class="order_flex" v-if="orderInfo.state > 2">
            <div class="left">快递方式</div>
            <div class="right">{{orderInfo.expressCompanyName}}</div>
         </div>
         <div class="order_all">
          合计：￥{{orderInfo.amount}}
         </div>
      </div>
    <bottomList></bottomList>
      <!-- last -->
      <div class="last_but">
          <span v-if="orderInfo.state == 1" @click.stop="doBuy(orderInfo)">去支付</span>
          <span v-if="orderInfo.state == 2 || orderInfo.state == 3">申请退款</span>
      </div>
  </div>
</template>

<script>
import { getOrderInfo, orderPay } from '@/api/order'

export default {
  name: "orderdetail",

  data() {
    return {
      orderInfo: {},
      NowTime: '', // 当前时间
    };
  },

  mounted() {
    this.getInfo()
    this.NowTime = this.getNowTime(true)
  },

  methods: {
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    // 物流详情
    gologistics(id) {
        this.$router.push('./logistics')
    },
    // 获取订单信息
    getInfo() {
      getOrderInfo(this.$route.params.orderId).then(res => {
        this.orderInfo = res.data
      })
    },
    // 获取当前时间
    getNowTime(isAll){
        let now = new Date();
        let year = now.getFullYear(); //获取完整的年份(4位,1970-????)
        let month = now.getMonth() + 1; //获取当前月份(0-11,0代表1月)
        let today = now.getDate(); //获取当前日(1-31)
        let hour = now.getHours(); //获取当前小时数(0-23)
        let minute = now.getMinutes(); //获取当前分钟数(0-59)
        let second = now.getSeconds(); //获取当前秒数(0-59)
        let nowTime = ''
        //返回年月日时分秒
        if(isAll){
        nowTime = year + '-' + this.fillZero(month) + '-' + this.fillZero(today) + ' ' + this.fillZero(hour) + ':' + this.fillZero(minute) + ':' + this.fillZero(second)
        }else{//返回年月日
          nowTime = year + '-' + this.fillZero(month) + '-' + this.fillZero(today)
        }
        return nowTime
    },
    // 给时间补零
    fillZero(str){
      var realNum;
      if (str < 10) {
          realNum = '0' + str;
      } else {
          realNum = str;
      }
      return realNum;
    },
    // 去支付
    doBuy(obj) {
        // 开始支付
        var payData = {
            payWay: 0,
            orderId: obj.orderId
        }
        orderPay(payData).then(ress => {
            if (ress.state == 200) {
                window.open(ress.data.url)
            }
        })
    }
  },
};
</script>

<style lang="less" scoped>
.orderinfo{
   background: #F6F7FA;
   padding-bottom: 40rem;
   width: 100vw;
   height: 100vh;
}
.title{
    position: relative;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    img{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
}
.status{
  width: calc(100vw - 32rem);
  height: 84rem;
  padding: 0rem 16rem;
  background: #000000;
  background: url('../../../../src/assets/image/orderinfo1.png');
  background-size: 100% 100%;
  .name{
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 800;
    font-size: 17rem;
    color: #FFFFFF;
    padding-top: 22rem;
  }
  .tig{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 13rem;
    color: #F6F7FA;
    margin-top: 2rem;
  }
}

.site{
    width: calc(100vw - 30rem);
    // height: 44rem;
    background: #FFFFFF;
    padding: 15rem;
    .site_text{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          display: flex;
          .imgdinwei{
            width: 20rem;
          }
          .text1{
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            font-size: 15rem;
            margin-left: 11rem;
            color: #333333;
          }
        }
        .imgright{
            width: 7rem;
        }
    }
    .time{
      font-family: PingFangSC, PingFang SC;
      font-weight: 100;
      font-size: 14rem;
      color: #000000;
      padding-left: 33rem;
      margin-top: 4rem;
    }
}
.site2{
  margin-top: 10rem;
  .imgdinwei{
    width: 23rem !important;
  }
}
.goods{
  margin-top: 10rem;
  display: flex;
  padding: 15rem;
  background: #fff;
  img{
    width: 98rem;
    height: 98rem;
  }
  .goodinfo{
    margin-left: 8rem;
    width: calc(100% - 116rem);
    .name{
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 14rem;
      color: #000000;
    }
    .tags{
      display: flex;
      margin-top: 5rem;
      justify-content: space-between;
      span{
          font-family: PingFangSC, PingFang SC;
          font-weight: 300;
          font-size: 13rem;
          color: #939297;
      }
    }
    .price{
      font-family: DINPro, DINPro;
      font-weight: 500;
      font-size: 14rem;
      color: #000000;
      margin-top: 32rem;
    }
  }
}
.order_nav{
  margin-top: 10rem;
  padding: 18rem 14rem;
  background: #fff;
  .order_flex{
    display: flex;
    justify-content: space-between;
    padding-bottom: 18rem;
    .left{
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 14rem;
      color: #666C73;
    }
    .right{
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 14rem;
      color: #000000;
    }
  }
  .order_all{
    padding-top: 14rem;
    text-align: right;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14rem;
    color: #000000;
    border-top: 1rem solid #F6F7FA;
  }
}
.last_but{
  width: 100vw;
  height: 60rem;
  line-height: 60rem;
  background: #FFFFFF;
  border: 1rem solid #F0F0F0;
  position: fixed;
  bottom: 0rem;
  left: 0rem;
  text-align: right;
  span{
    display: inline-block;
    line-height: 44rem;
    width: 107rem;
    height: 44rem;
    text-align: center;
    border: 1rem solid #C5C8D3;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15rem;
    color: #000000;
    margin-right: 9rem;
    margin-top: 7rem;
  }
  .qrsh{
    background: #E2BFB0;
    border: none !important;
  }
}
</style>
